body {
  margin: 0;
  font-family: verdana;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color : #5a0087 !important;
}

.MuiFormLabel-root.Mui-focused {
  color : #5a0087 !important;
}

.pixel {
  image-rendering : crisp-edges;
}

a  {
  text-decoration : none;
  color : #5a0087;
  font-weight: bold;
}
